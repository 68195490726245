import { enGB } from 'date-fns/locale/en-GB';
import { enUS } from 'date-fns/locale/en-US';
import { enCA } from 'date-fns/locale/en-CA';
import { de } from 'date-fns/locale/de';
import { underscoredToCamel } from 'utils/helpers';
import invert from 'lodash/invert';
import { isDomAvailable } from './ssr';

export const APP_NAME = 'SessionLab';
export const APP_HOST = process.env.RAILS_APP_HOST as string;
export const PUBLIC_HOST = process.env.PUBLIC_HOST;

export const RECAPTCHA_SCRIPT_URL =
  'https://www.google.com/recaptcha/api.js?render=' +
  process.env.RECAPTCHAV3_SITE_KEY;
export const userTz = Intl && Intl.DateTimeFormat().resolvedOptions().timeZone;

export const CALENDAR_LOCALES = {
  en: enGB,
  'en-GB': enGB,
  'en-NZ': enGB,
  'en-AU': enGB,
  'en-CA': enCA,
  'en-US': enUS,
  de: de,
};
let localeKeyWithFallback = 'en';
if (isDomAvailable) {
  localeKeyWithFallback = Object.keys(CALENDAR_LOCALES).includes(
    window.navigator.language,
  )
    ? window.navigator.language
    : 'en';
}

export const CURRENT_DATE_LOCALE = CALENDAR_LOCALES[localeKeyWithFallback];

export const TIME_PERIODS = {
  am: 'AM',
  pm: 'PM',
};

export const MAX_DATE = new Date('9999-12-31');

export const MAX_MINUTES_IN_DAY = 1440;
export const MINUTES_IN_12_HOURS = MAX_MINUTES_IN_DAY / 2;
export const MIN_MINUTES_IN_DAY = 0;

export const FOLDER_ICONS = {
  home: 'history',
  shared: 'user-group',
  team: 'users',
  search: 'search',
  locked: 'lock',
  folder: 'folder',
  trash: 'trash',
};

export const FREE_WORKSPACE_SESSION_LIMIT = Number(
  process.env.FREE_WORKSPACE_SESSION_LIMIT,
);
export const RECENT_LIMIT = 24;
export const EMAIL_INVITATIONS_LIMIT = 10;
export const BLOCK_ACTIVITY_TIMEOUT = 10000;
export const EDITOR_OFFSET_TOP = 51;
export const CHANGELOG_LIMIT = 20;
export const MAX_BLOCK_DEPTH = 3; // Group with breakout with group with regular block

export const PAGE_TITLES = {
  default: `${APP_NAME}`,
  template: `%s | ${APP_NAME}`,
};

export const DASHBOARD_VIEW_TYPES = {
  home: 'home',
  folder: 'folder',
  shared: 'shared',
  team: 'team',
  search: 'search',
  locked: 'locked',
  trash: 'trash',
};

export const TEMPLATES_VIEW_TYPES = {
  all: 'all',
  public: 'public',
  team: 'team',
};

export const TEMPLATES_DURATION_FILTER = {
  all: 'all',
  lessDay: 'lessDay',
  day: 'day',
  multi: 'multi',
};

export const TEMPLATES_VIEW_SORT = {
  order: 'order',
  createdAt: 'createdAt',
  name: 'name',
};

export const COOKIES = {
  folderViewType: 'folderViewType',
  folderSortedBy: 'folderSortedBy',
  folderSortedReverse: 'folderSortedReverse',
  seenTutorials: 'sessionlab_seen_tutorials',
  delayUpgradeAlertPrefix: `sl_ul_${process.env.RAILS_ENV}`,
  promoDismissed: 'dismissRewardPromo',
  materialsOrder: 'materialsOrder',
  displayTimeZone: 'sessionlab_display_tz_',
  anonUserVisit: 'anon_user_visit',
  isMinimapHidden: 'isMinimapHidden',
};

export const VIEW_TYPES = {
  list: 'list',
  grid: 'grid',
} as const;

export const SORTING_ORDER = {
  desc: 'desc',
  asc: 'asc',
};

export const SORTING_BY = {
  lastEdit: 'lastEditAt',
  startDate: 'startDate',
  name: 'name',
  client: 'client',
};

export const SORT_DEFAULT: SORTING_BY_VALUES = SORTING_BY.lastEdit;

export type SORTING_BY_VALUES = (typeof SORTING_BY)[keyof typeof SORTING_BY];

export const OWNER_TYPES = {
  user: 'User',
  team: 'Team',
};

export const APP_VIEWS = {
  dashboard: 'dashboard',
  plannerSingleView: 'plannerSingle',
  plannerMultiView: 'plannerMulti',
  plannerOverview: 'plannerOverview',
};

export const DIALOGS = {
  1001: 'limitDialog',
  duplicate: 'duplicateLimitDialog',
  duplicateMultiDay: 'duplicateMultiLimitDialog',
  restoreSession: 'restoreLimitDialog',
  duplicateBreakout: 'duplicateBreakoutLimitDialog',
  createBreakout: 'createBreakoutLimitDialog',
  copyBreakout: 'copyBreakoutLimitDialog',
  addTrack: 'addTrackLimitDialog',
  duplicateTrack: 'duplicateTrackLimitDialog',
  addMultiDay: 'addMultiDayDialog',
  moveToFolder: 'moveToFolder',
  shareSession: 'shareSession',
  openSession: 'openSession',
  previewSession: 'previewSession',
  invite: 'invite',
  addToLibrary: 'addToLibrary',
  copyBlock: 'copyBlock',
  moveBlock: 'moveBlock',
  method: 'method',
  print: 'print',
  upgradeToPro: 'upgradeToPro',
  proDialog: 'proDialog',
  addMembersDialog: 'addMembersDialog',
  createTeamDialog: 'createTeamDialog',
  onboardingSuccess: 'onboardingSuccess',
  addSeats: 'addSeats',
  delete: 'delete',
  typeForm: 'typeForm',
  createTemplate: 'createTemplateDialog',
  cancelDialog: 'cancelDialog',
  exportWord: 'exportWord',
  exportPpt: 'exportPpt',
  createSessionVersionDialog: 'createSessionVersionDialog',
  restoringSessionVersion: 'restoringSessionVersion',
  upgradeToCreateWorkspace: 'upgradeToCreateWorkspace',
  promotePersonalSessions: 'promotePersonalSessions',
  generic: 'generic',
  extractDay: 'extractDay',
  renameSession: 'renameSession',
  newSession: 'newSession',
  deleteWorkspaceMember: 'deleteWorkspaceMember',
  changeWorkspaceOwner: 'changeWorkspaceOwner',
} as const;

export const ALERTS = {
  danger: 'danger',
  info: 'info',
  success: 'success',
  warning: 'warning',
  normal: 'normal',
} as const;

export type Alert = (typeof ALERTS)[keyof typeof ALERTS];

export const ITEM_TYPES = {
  block: 'Block',
  blockTrainer: 'BlockTrainer',
  collaboration: 'Collaboration',
  collaborationInvitation: 'CollaborationInvitation',
  comment: 'Comment',
  session: 'Session',
  folder: 'Folder',
  reference: 'Reference',
  team: 'Team',
  topic: 'Topic',
  snapshot: 'Snapshot',
} as const;

export const ROLES = {
  owner: 0,
  viewer: 1,
  editor: 2,
} as const;

export type RoleType = (typeof ROLES)[keyof typeof ROLES];
export type RoleName = keyof typeof ROLES;

export const ROLE_ICONS = {
  [ROLES.owner]: 'fa-light fa-chess-rook',
  [ROLES.viewer]: 'fa fa-eye',
  [ROLES.editor]: 'fa fa-pencil',
};

export const ROLES_TO_CHANGE = {
  [ROLES.editor]: ROLES.viewer,
  [ROLES.viewer]: ROLES.editor,
  [ROLES.owner]: null,
};

export const TEAM_ROLES = {
  // `owner` is special role, not directly mapped to collaboration.role column, but rather is the case
  // when user is team.account.owner and needs to be manully checked on the frontend. So that's why we
  // have string instead of number, to not clash with actual roles comming from the backend.
  owner: 'team-owner',
  admin: 0,
  member: 1,
};

export const TEAM_ROLE_ICONS = {
  [TEAM_ROLES.owner]: 'fa-light fa-crown',
  [TEAM_ROLES.admin]: 'fa-light fa-chess-rook',
  [TEAM_ROLES.member]: 'fa fa-user',
};

export const TEAM_ROLE_TO_CHANGE = {
  [TEAM_ROLES.admin]: TEAM_ROLES.member,
  [TEAM_ROLES.member]: TEAM_ROLES.admin,
};

export const ROLES_FOR_ITEM = {
  [ITEM_TYPES.session]: {
    roles: ROLES,
    keys: invert(ROLES),
    toChange: ROLES_TO_CHANGE,
    icons: ROLE_ICONS,
    default: ROLES.editor,
  },
  [ITEM_TYPES.team]: {
    roles: TEAM_ROLES,
    keys: invert(TEAM_ROLES),
    toChange: TEAM_ROLE_TO_CHANGE,
    icons: TEAM_ROLE_ICONS,
    default: TEAM_ROLES.member,
  },
} as const;

export const ERROR_CODES = {
  unauthorized: 401,
  forbidden: 403,
  limit: 1001,
  notSignedIn: 1002,
  teamSeatLimitReached: 2002,
  accountNeedsUnlocking: 2003,
  teamRequiresPayment: 2004,
  canNotProcess: 400,
  notFound: 404,
  duplicate: 'duplicate',
  duplicateMultiDay: 'duplicateMultiDay',
  addMultiDay: 'addMultiDay',
};

export const INVITATION_TYPES = {
  session: 'session',
  regular: 'regular',
  link: 'link',
};

export const SOCIAL_NETWORKS = {
  facebook: {
    name: 'Facebook',
    shareWidth: 520,
    shareHeight: 520,
    url: 'https://www.facebook.com/dialog/share?app_id=1172832052745934&display=popup&href=%url&redirect_uri=%redirect&quote=%text',
  },
  linkedin: {
    name: 'LinkedIn',
    shareWidth: 570,
    shareHeight: 520,
    url: 'https://www.linkedin.com/shareArticle?mini=true&source=TrainedOn&title=%title&summary=%text&url=%url',
  },
  twitter: {
    name: 'Twitter',
    shareWidth: 520,
    shareHeight: 420,
    url: 'https://twitter.com/intent/tweet?text=%text&url=%url&via=SessionLab',
  },
};

export const TRANSLATION_KEYS = {
  workspaceError: 'errors.workspace_loading',
};

export const DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
} as const;

export const ID_PREFIXES = {
  new: 'new_',
  copy: 'copy_of_',
};

export const DOM_PLACEHOLDER_IDS = {
  banner: 'dashboard-banner',
  header: 'react-header',
};

export const POPPER_CONTAINER_ID = 'popper-target-portal';

// On mobile dropdowns we want to have a close button
// It was a huge task to replace react-bootstrap's dropdown
// What we're doing is having a dummy clicker that we click programmatically when the users click the fake close button
export const DUMMY_CLICKER_ID = 'dummy-clicker';

export const TAG_NEW_TEMPLATE = '<<<%s>>>';

export const TASK_CSS_CLASS = 'sl-task';

export const PREVENT_BLOCK_INFO_SWITCH_IN_GROUP_CLASS = 'prevent-info-switch';

export const LOCAL_SYNC_CHANNELS = {
  sessions: 'sessions',
};

export const SYNC_ACTIONS = {
  create: 'create',
  restore: 'restore',
  destroy: 'destroy',
  discard: 'discard',
  materialAdded: 'material_added',
  materialRemoved: 'material_removed',
  replace: 'replace',
  update: 'update',
  sessionRestored: 'session_restored',
  restoringSession: 'restoring_session',
  stop: 'stop',
  parentUpdated: 'parent_updated',
  newDayInMultiday: 'new_day_in_multiday',
  currentRunUpdate: 'current_run_update',
  snapshotCreated: 'snapshot_created',
  snapshotRestored: 'restored_from_snapshot',
} as const;

export const PLANNER_VERTICAL_TABS = {
  notes: 'notes',
  search: 'search',
  comments: 'comments',
  attachments: 'attachments',
  blockDetails: 'block-details',
  activity: 'activity',
  collaborators: 'collaborators',
  tasks: 'tasks-materials',
  ai: 'ai',
};

export const TREE_EXPANDED_MODES = {
  all: 'all',
  none: 'none',
  current: 'current',
};

export const KEY_CODES = {
  backspace: 8,
  tab: 9,
  return: 13,
  alt: 18,
  esc: 27,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
};

export const AUDIT_ACTIONS = {
  create: 'create',
  update: 'update',
  destroy: 'destroy',
  position: 'position',
  status: 'status',
};

export const MATERIAL_PROVIDERS = {
  dropbox: 1,
  google: 2,
  onedrive: 3,
};

export const MST_ACTION_TYPES = {
  action: 'action',
  flow_spawn: 'flow_spawn',
  flow_resume: 'flow_resume',
  flow_resume_error: 'flow_resume_error',
  flow_return: 'flow_return',
  flow_throw: 'flow_throw',
};

export const COLORS = [1, 2, 3, 4, 5, 6, 7, 8];

export const MATERIALS_ORDER = {
  alpha: 'alpha',
  sequence: 'sequence',
};

export const EXPERIMENTS =
  typeof process.env.AB_EXPERIMENTS === 'object'
    ? process.env.AB_EXPERIMENTS
    : Object.keys(JSON.parse(process.env.AB_EXPERIMENTS || '{}')).reduce(
        (result: Record<string, string>, value: string) => {
          result[underscoredToCamel(value)] = value;
          return result;
        },
        {},
      );

export const SUBSCRIPTION_PLANS = {
  free: 'free',
  individual: 'individual',
  pro: 'pro',
  pro_unlimited: 'pro_unlimited',
  business: 'business',
  enterprise: 'enterprise',
} as const;

export type SubscriptionPlansKeys = keyof typeof SUBSCRIPTION_PLANS;
export type SubscriptionPlansType =
  (typeof SUBSCRIPTION_PLANS)[SubscriptionPlansKeys];

// Values need to be like this (Capital case) to match the backend logic
export const PLAN_FREQUENCY = {
  annual: 'Year',
  monthly: 'Month',
} as const;

export type PlanFrequencyKeys = keyof typeof PLAN_FREQUENCY;
export type PlanFrequencyType = (typeof PLAN_FREQUENCY)[PlanFrequencyKeys];

export interface PlanOption {
  benefits: number[];
  [PLAN_FREQUENCY.annual]: { price: number };
  [PLAN_FREQUENCY.monthly]: { price: number };
}

export const PLAN_OPTIONS: Record<SubscriptionPlansType, PlanOption> = {
  [SUBSCRIPTION_PLANS.free]: {
    benefits: [0, 1, 2, 3, 4, 5],
    [PLAN_FREQUENCY.annual]: {
      price: 0,
    },
    [PLAN_FREQUENCY.monthly]: {
      price: 0,
    },
  },
  [SUBSCRIPTION_PLANS.individual]: {
    benefits: [0, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    [PLAN_FREQUENCY.annual]: {
      price: 9,
    },
    [PLAN_FREQUENCY.monthly]: {
      price: 14,
    },
  },
  [SUBSCRIPTION_PLANS.pro]: {
    benefits: [16, 17, 18, 19, 20, 21, 22, 23, 24, 33],
    [PLAN_FREQUENCY.annual]: {
      price: 15,
    },
    [PLAN_FREQUENCY.monthly]: {
      price: 23,
    },
  },
  [SUBSCRIPTION_PLANS.business]: {
    benefits: [25, 26, 27, 28, 29, 30, 31, 32],
    [PLAN_FREQUENCY.annual]: {
      price: 23,
    },
    [PLAN_FREQUENCY.monthly]: {
      price: 35,
    },
  },
  [SUBSCRIPTION_PLANS.pro_unlimited]: {
    benefits: [30, 31, 29],
    [PLAN_FREQUENCY.monthly]: {
      price: 300,
    },
    [PLAN_FREQUENCY.annual]: {
      price: 300,
    },
  },
};

export const LOCAL_STORAGE_KEYS = {
  MISSING_TRANSLATION_WARNINGS: 'MISSING_TRANSLATION_WARNINGS',
  OVERDUE_PAYMENT_DATE: 'OVERDUE_PAYMENT_DATE',
  PLAN_DETAILS_STATE: 'PLAN_DETAILS_STATE',
  DOWN_TIME_ALERT: 'DOWN_TIME_ALERT',
  COMMENTS_SORTING_BY: 'COMMENTS_SORTING_BY',
  LOCKED_ALERT_DISMISSED: 'LOCKED_ALERT_DISMISSED',
  USER_CONF_DATE: 'USER_CONF_DATE',
  HIDDEN_DISPLAY_TIME_ZONE_BANNERS: 'HIDDEN_DISPLAY_TIME_ZONE_BANNERS',
};

export const LOADING_STATUS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const TIME_TRACKER_STATUS = {
  NOT_RUNNING: 'NOT_RUNNING',
  RUNNING: 'RUNNING',
  FINISHING: 'FINISHING',
} as const;

export const TASK_FILTER = {
  All: 'All',
  Completed: 'Completed',
  Uncompleted: 'Uncompleted',
};

export const EXTENSION_TO_ICON = {
  aac: 'volume',
  abw: 'file-text',
  arc: 'file-zipper',
  avif: 'image',
  avi: 'video',
  azw: 'book',
  bin: 'file',
  bmp: 'image',
  bz: 'file-zipper',
  bz2: 'file-zipper',
  cda: 'volume',
  csh: 'code',
  css: 'code',
  csv: 'file-csv',
  doc: 'file-text',
  docx: 'file-text',
  eot: 'font-case',
  epub: 'book',
  gz: 'file-zipper',
  gif: 'image',
  htm: 'code',
  html: 'code',
  ico: 'image',
  ics: 'calendar-day',
  jar: 'code',
  jpeg: 'image',
  jpg: 'image',
  js: 'code',
  json: 'code',
  jsonld: 'code',
  mid: 'volume',
  mjs: 'code',
  mp3: 'volume',
  mp4: 'video',
  mpeg: 'video',
  mpkg: 'file-zipper',
  odp: 'file-powerpoint',
  ods: 'file-spreadsheet',
  odt: 'file-text',
  oga: 'volume',
  ogv: 'video',
  ogx: 'volume',
  opus: 'volume',
  otf: 'font-case',
  png: 'image',
  pdf: 'file-pdf',
  php: 'code',
  ppt: 'file-powerpoint',
  pptx: 'file-powerpoint',
  rar: 'file-zipper',
  rtf: 'file-text',
  sh: 'code',
  svg: 'image',
  tar: 'file-zipper',
  tif: 'image',
  ts: 'video',
  ttf: 'font-case',
  txt: 'file-text',
  vsd: 'diagram-project',
  wav: 'volume',
  weba: 'volume',
  webm: 'video',
  webp: 'image',
  woff: 'font-case',
  woff2: 'font-case',
  xhtml: 'code',
  xls: 'file-spreadsheet',
  xlsx: 'file-spreadsheet',
  xml: 'code',
  xul: 'code',
  zip: 'file-zipper',
  '3gp': 'volume',
  '3g2': 'volume',
  '7z': 'file-zipper',
};

export const DEFAULT_SESSION_NAME = 'planner.default_session_name';
